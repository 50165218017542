import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 中控台

// 概览
export function fetchStatics(data) {
  return request({
    url: '/liveapi/index/liveStatics',
    method: 'post',
    data: param2Obj(data)
  })
}

// 直播管理
export function getLiveList(data) {
  return request({
    url: '/liveapi/live/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLive(data) {
  return request({
    url: '/liveapi/live/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function onBroadcast(data) {
  return request({
    url: '/liveapi/live/info',
    method: 'post',
    data: param2Obj(data)
  })
}

export function copyShareCode(data) {
  return request({
    url: '/liveapi/live/copy/share/code',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchChannelSection(data) {
  return request({
    url: '/liveapi/live/treetags',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchShopGoods(data) {
  return request({
    url: '/liveapi/live/goods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLiveGoods(data) {
  return request({
    url: '/liveapi/live/pageLiveGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function uploadLiveGoods(data) {
  return request({
    url: '/liveapi/live/addliveGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function openPushFlow(data) {
  return request({
    url: '/liveapi/live/standard/room/url',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchAliyunAuthToken(data) {
  return request({
    url: '/liveapi/live/aliyunAuthToken',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createLive(data) {
  return request({
    url: '/liveapi/live/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateLive(data) {
  return request({
    url: '/liveapi/live/updateinfo',
    method: 'post',
    data: param2Obj(data)
  })
}

export function endLive(data) {
  return request({
    url: '/liveapi/live/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteLive(data) {
  return request({
    url: '/liveapi/live/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchPlaybackStatistics(data) {
  return request({
    url: '/liveapi/live/statistics',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchPlayback(data) {
  return request({
    url: '/liveapi/live/playback',
    method: 'post',
    data: param2Obj(data)
  })
}

// 直播装修
export function getInfoCardList(data) {
  return request({
    url: '/liveapi/infoCard/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchInfoCard(data) {
  return request({
    url: '/liveapi/infoCard/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createInfoCard(data) {
  return request({
    url: '/liveapi/infoCard/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updeteInfoCard(data) {
  return request({
    url: '/liveapi/infoCard/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteInfoCard(data) {
  return request({
    url: '/liveapi/infoCard/updateStatus',
    method: 'post',
    data: param2Obj(data)
  })
}

// 直播互动
export function getCouponList(data) {
  return request({
    url: '/liveapi/marketing/coupon/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCoupon(data) {
  return request({
    url: '/liveapi/marketing/live/coupon/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function activeCoupon(data) {
  return request({
    url: '/liveapi/marketing/coupon/active',
    method: 'post',
    data: param2Obj(data)
  })
}

export function cancelCoupon(data) {
  return request({
    url: '/liveapi/marketing/live/coupon/cancel',
    method: 'post',
    data: param2Obj(data)
  })
}

// 直播业绩
export function achievementPanel(data) {
  return request({
    url: '/liveapi/live/achievement/market',
    method: 'post',
    data: param2Obj(data)
  })
}

export function achievementLive(data) {
  return request({
    url: '/liveapi/live/achievement/listMarket',
    method: 'post',
    data: param2Obj(data)
  })
}

export function achievementLiveOrder(data) {
  return request({
    url: '/liveapi/live/achievement/pageLiveOrder',
    method: 'post',
    data: param2Obj(data)
  })
}

export function achievementLiveTimes(data) {
  return request({
    url: '/liveapi/live/achievement/pageLive',
    method: 'post',
    data: param2Obj(data)
  })
}

export function achievementLiveGoods(data) {
  return request({
    url: '/liveapi/live/achievement/pageLiveGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchAchievementTimes(data) {
  return request({
    url: '/liveapi/live/achievement/pageLiveTimes',
    method: 'post',
    data: param2Obj(data)
  })
}

// 流量分析
export function fetchAnalysis(data) {
  return request({
    url: '/liveapi/live/user/flow/analysis',
    method: 'post',
    data: param2Obj(data)
  })
}
