<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-title">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ name: 'teacherShop' }">{{$t('teacher.breadcrumb.shopManage')}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{$t('teacher.breadcrumb.edit')}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-wrap">
				<el-form :model="pageInfo" :rules="pageRules" ref="pageInfo" size="small" label-width="200px">
					<el-form-item :label="$t('teacher.formItem.studentName')" prop="real_name">
						<el-input v-model="pageInfo.real_name" class="w400" maxlength="10" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopOwnerCard')" prop="owner_card">
						<el-input v-model="pageInfo.owner_card" class="w400" maxlength="18" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')" prop="store_name">
						<el-input v-model="pageInfo.store_name" class="w400" maxlength="20" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongCate')">
						<el-select v-model="pageInfo.cate_id">
							<el-option v-for="(item,index) in cateOption" :key="index" :label="item.tag_name"
								:value="item.tag_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.openTime')">{{pageInfo.open_time | parseTime()}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.addresses')">
						<el-row style="margin-bottom: 18px;">
							<el-col :span="8" v-for="(item,index) in regionList" :key="index" style="padding-right: 10px;">
								<el-select v-model="regionIds[index]" clearable @change="handleRegionChange($event,index)">
									<el-option
										v-for="option in item"
										:key="option.region_id"
										:label="option.region_name"
										:value="option.region_id">
									</el-option>
								</el-select>
							</el-col>
						</el-row>
						<el-input type="textarea" v-model="pageInfo.address" :placeholder="$t('teacher.placeholder.fullAddress')" class="w400" maxlength="60" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.zipcode')">
						<el-input v-model="pageInfo.zipcode" class="w400" maxlength="10" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.telephone')">
						<el-input v-model="pageInfo.tel" class="w400" maxlength="20" :disabled="true" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')" prop="class_id">
						<el-select v-model="pageInfo.class_id" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.status')">
						<el-radio-group v-model="pageInfo.state">
							<el-radio :label="0">{{$t('teacher.tags.open')}}</el-radio>
							<el-radio :label="1">{{$t('teacher.tags.close')}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<!---<el-form-item :label="$t('teacher.formItem.startPay')">
						<el-radio-group v-model="pageInfo.open_pay">
							<el-radio :label="1">{{$t('teacher.tags.open')}}</el-radio>
							<el-radio :label="0">{{$t('teacher.tags.close')}}</el-radio>
						</el-radio-group>
					</el-form-item>-->
					<el-form-item>
						<el-button type="primary" @click="handleSubmit">{{$t('teacher.actions.submit')}}</el-button>
						<el-button @click="getPageInfo">{{$t('teacher.actions.reset')}}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { fetchChannelSection } from '@/api/liveplatform';
	import { fetchClass, fetchShop, updateShop } from '@/api/teacher';
	export default {
		data() {
			var validateAddress = (rule, value, callback) =>{
				if(this.pageInfo.address === ''){
					callback(new Error(this.$t('teacher.validate.required')));
				}else if(!this.regionIds.length>0){
					callback(new Error(this.$t('teacher.validate.region')));
				}else {
					callback();
				}
			}
			var validatePhone = (rule, value, callback)=>{
				let string = /^1[3-9]\d{9}$/
				if(value==='' || !string.test(value)){
					callback(new Error(this.$t('teacher.validate.phone')));
				}else {
					callback();
				}
			}
			return {
				pageInfo: {},
				pageRules: {
					real_name: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					],
					owner_card: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					],
					store_name: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					],
					// cate_id: [
					// 	{ required: true, message: this.$t('teacher.validate.required'), trigger: 'change' }
					// ],
					// address: [
					// 	{ required: true, validator: validateAddress, trigger: 'blur' }
					// ],
					// zipcode: [
					// 	{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					// ],
					// tel: [
					// 	{ required: true, validator: validatePhone, trigger: 'blur' }
					// ],
					class_id: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					]
				},
				cateOption: [],
				classOption: [],
				regionList: [],
				regionIds: [],
			}
		},
		mounted() {
			this.getCateOption();
			this.getClassOption();
			this.getPageInfo();
		},
		methods: {
			getCateOption(){
				let params = {
					page_index: 0,
					page_size: 1000,
					app_key: this.$store.getters.appKey
				}
				fetchChannelSection(params).then(response => {
					this.cateOption = response.data.list;
				})
			},
			getClassOption(){
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageInfo() {
				fetchShop({store_id: this.$route.params.id}).then(response => {
					this.pageInfo = response.data;
					if(!this.pageInfo.cate_id>0){
						this.pageInfo.cate_id = '';
					}
					let promiseArr = [this.initRegionList()];
					let regionList = [];
					let regionIds = [];
					for(let i=1;i<4;i++){
						if(response.data['region_id' + i] > 0){
							regionIds.push(response.data['region_id' + i]);
							promiseArr.push(this.initRegionList(response.data['region_id' + i]));
						}
					}
					Promise.all(promiseArr).then(object=>{
						object.forEach(item=>{
							if(item.length>0){
								regionList.push(item);
							}
						})
					})
					this.regionList = regionList;
					this.regionIds = regionIds;
					this.$nextTick(function(){
						this.$refs.pageInfo.clearValidate();
					})
				})
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list);
					}).catch(error => {
						reject(error);
					})
				})
			},
			getRegionList(pid=45056,index=-1){
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			handleRegionChange(val, index){
				if(val){
					this.regionIds.splice(index + 1);
					this.getRegionList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.regionList.splice(index+1);
				}
			},
			handleSubmit(){
				let loading = this.$loading();
				if(this.regionIds){
					this.pageInfo.region_id = this.regionIds[this.regionIds.length-1];
					this.pageInfo.region_id1 = this.regionIds[0] > 0 ? this.regionIds[0] : 0;
					this.pageInfo.region_id2 = this.regionIds[1] > 0 ? this.regionIds[1] : 0;
					this.pageInfo.region_id3 = this.regionIds[2] > 0 ? this.regionIds[2] : 0;
					let regionName = '';
					for(let i = 0;i<this.regionIds.length;i++){
						this.regionList[i].forEach(item=>{
							if(item.region_id == this.regionIds[i]){
								regionName+=item.region_name;
							}
						})
					}
					this.pageInfo.region_name = regionName;
				}
				this.$refs.pageInfo.validate((valid)=>{
					if(valid){
						updateShop(this.pageInfo).then(() => {
							loading.close();
							this.$message.success(this.$('teacher.success.update'));
							this.$router.push({name:'teacherShop'})
						}).catch(() => {
							loading.close();
						})
					}else {
						loading.close();
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;
			margin-bottom: 20px;
			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}
		.t-page-wrap {
			width: 800px;
			.w400 {
				width: 400px;
			}
			::v-deep .el-textarea {
				.el-input__count {
					line-height: 1em;
				}
			}
		}
	}
</style>
