import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 教师
export function fetchStatistics(data) {
  return request({
    url: '/teacher/platform/statistics/index',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getClassList(data) {
  return request({
    url: '/teacher/platform/class/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function uploadPayment(data) {
  return request({
    url: '/teacher/platform/class/openAlipay',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchClass(data) {
  return request({
    url: '/teacher/platform/class/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getGoodsList(data) {
  return request({
    url: '/teacher/platform/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function goodsOffShelf(data) {
  return request({
    url: '/teacher/platform/goods/violation/offSelf',
    method: 'post',
    data: param2Obj(data)
  })
}

export function goodsRestore(data) {
  return request({
    url: '/teacher/platform/goods/violation/upSelf',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getLiveList(data) {
  return request({
    url: '/teacher/platform/live/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getLiveFieldList(data) {
  return request({
    url: '/teacher/platform/live/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getLiveBroadcastList(data) {
  return request({
    url: '/teacher/platform/live/pageLiving',
    method: 'post',
    data: param2Obj(data)
  })
}

export function closeLive(data) {
  return request({
    url: '/teacher/platform/live/force/close',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLiveProblemBarrageList(data) {
  return request({
    url: '/teacher/platform/live/issue/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createLiveProblemBarrage(data) {
  return request({
    url: '/teacher/platform/live/issue/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateLiveProblemBarrage(data) {
  return request({
    url: '/teacher/platform/live/issue/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteLiveProblemBarrage(data) {
  return request({
    url: '/teacher/platform/live/issue/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getMemberList(data) {
  return request({
    url: '/teacher/platform/member/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getOrderList(data) {
  return request({
    url: '/teacher/platform/order/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchOrder(data) {
  return request({
    url: '/teacher/platform/order/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getShopList(data) {
  return request({
    url: '/teacher/platform/store/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchShop(data) {
  return request({
    url: '/teacher/platform/store/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateShop(data) {
  return request({
    url: '/teacher/platform/store/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteShop(data) {
  return request({
    url: '/teacher/platform/store/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchSystemScore(data) {
  return request({
    url: '/teacher/platform/score/student/intellect/score/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getScoreRanking(data) {
  return request({
    url: '/teacher/platform/score/pageRanking',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchStudentScore(data) {
  return request({
    url: '/teacher/platform/score/student/score/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function saveTeacherScore(data) {
  return request({
    url: '/teacher/platform/score/student/score/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchPayment(data) {
  return request({
    url: '/teacher/platform/order/listPaymentTypes',
    method: 'post',
    data: param2Obj(data)
  })
}

export function calculateSystem(data) {
  // return request({
  //   url: '/teacher/platform/order/listPaymentTypes',
  //   method: 'post',
  //   data: param2Obj(data)
  // })
}

export function fetchMarketList(data) {
  return request({
    url: '/teacher/platform/marketing/activity/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMarketInfo(data) {
  return request({
    url: '/teacher/platform/marketing/activity/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createMarket(data) {
  return request({
    url: '/teacher/platform/marketing/activity/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateMarket(data) {
  return request({
    url: '/teacher/platform/marketing/activity/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteMarket(data) {
  return request({
    url: '/teacher/platform/marketing/activity/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMarketTags(data) {
  return request({
    url: '/teacher/platform/marketing/activity/label/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTagsList(data) {
  return request({
    url: '/teacher/platform/marketing/label/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTagsInfo(data) {
  return request({
    url: '/teacher/platform/marketing/label/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createTags(data) {
  return request({
    url: '/teacher/platform/marketing/label/save',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateTags(data) {
  return request({
    url: '/teacher/platform/marketing/label/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteTags(data) {
  return request({
    url: '/teacher/platform/marketing/label/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMarketCate(data) {
  return request({
    url: '/teacher/platform/marketing/activity/listCate',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMarketGoods(data) {
  return request({
    url: '/teacher/platform/marketing/activity/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function uploadGoodsStatus(data) {
  return request({
    url: '/teacher/platform/marketing/activity/goods/examine',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMarketGoodsInfo(data) {
  return request({
    url: '/teacher/platform/marketing/activity/goods/detail',
    method: 'post',
    data: param2Obj(data)
  })
}
// 创意
export function fetchCcreativeList(data) {
  return request({
    url: '/teacher/platform/promotion/feed/creative/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateCcreativeStatue(data) {
  return request({
    url: '/teacher/platform/promotion/feed/creative/examine',
    method: 'post',
    data: param2Obj(data)
  })
}
// 落地页
export function fetchTeemoList(data) {
  return request({
    url: '/teacher/platform/promotion/feed/langding/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateTeemoStatue(data) {
  return request({
    url: '/teacher/platform/promotion/feed/langding/examine',
    method: 'post',
    data: param2Obj(data)
  })
}
// 班级类目资金
export function fetchClassFundCate(data) {
  return request({
    url: '/teacher/platform/class/product/list/category',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateClassCateFund(data) {
  return request({
    url: '/teacher/platform/class/product/update/category_amount',
    method: 'post',
    data: param2Obj(data)
  })
}

export function restoreClassCateFund(data) {
  return request({
    url: '/teacher/platform/class/product/batch/restore',
    method: 'post',
    data: param2Obj(data)
  })
}
// 学生类目资金
export function fetchStudentFundCate(data) {
  return request({
    url: '/teacher/platform/member/product/list/category',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateStudentCateFund(data) {
  return request({
    url: '/teacher/platform/member/product/update/category_amount',
    method: 'post',
    data: param2Obj(data)
  })
}

export function restoreStudentCateFund(data) {
  return request({
    url: '/teacher/platform/member/product/batch/restore',
    method: 'post',
    data: param2Obj(data)
  })
}

